<script>

const MessageRepository = Repository.get("MessageRepository");
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";
import UnreadMessageCount from "@/components/message/unread-message-count";

export default {
  components: {
      UnreadMessageCount
  },
  props: {},
  data() {
    return {
      countUnreadMessages: 0,
    }
  },
  methods: {
    isInEditMode() {
      return this.$router.history.current.name === 'Edycja wiadomości';
    },
    messageCategory() {
      return this.$route.params.messageCategory;
    },
    createDraft() {
      this.inProgress = true;
      const payload = {
        title: 'Tytuł wiadomości',
        content: '',
        label: 'info'
      };

      MessageRepository.createDraft(payload).then((response) => {
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Wiadomość została dodana!',
          showConfirmButton: false,
          timer: 1500
        });
        this.inProgress = false;
        this.$router.push({ name: 'Edycja wiadomości', params: { messageCategory: 'draft', id: response.data.id } });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });

        this.inProgress = false;
      })
    },
  },
}
</script>
<template>
  <div>
    <b-button variant="danger" :disabled="isInEditMode()" v-on:click="createDraft">Utwórz wiadomość</b-button>
    <div class="mail-list mt-4">
      <b-link :to="{ name: 'Lista wiadomości', params: { messageCategory: 'inbox' } }"
              :class="messageCategory() === 'inbox' ? 'active' : ''">
        <i class="mdi mdi-email-outline mr-2"></i>Skrzynka odbiorcza
        <span class="ml-1 float-right"></span>
      </b-link>
      <b-link :to="{ name: 'Lista wiadomości', params: { messageCategory: 'unread' } }"
              :class="messageCategory() === 'unread' ? 'active' : ''">
        <i class="mdi mdi-email-variant mr-2"></i>Nieprzeczytane
          <unread-message-count style="margin-top: 5px; !important;"/>
      </b-link>
      <b-link :to="{ name: 'Lista wiadomości', params: { messageCategory: 'outbox' } }"
              :class="messageCategory() === 'outbox' ? 'active' : ''">
        <i class="mdi mdi-email-send-outline mr-2"></i>Elementy wysłane
      </b-link>
      <b-link :to="{ name: 'Lista wiadomości', params: { messageCategory: 'draft' } }"
              :class="messageCategory() === 'draft' ? 'active' : ''">
        <i class="mdi mdi-email-edit-outline mr-2"></i>Kopie robocze
      </b-link>
    </div>
  </div>
</template>
